import {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {allowTestOnly, isFeatureEnabled} from '../../_gori/helpers'
import UseRoutesPermission from '../../_gori/hooks/UseRoutesPermission'
import {MasterLayout} from '../../_gori/layout/MasterLayout'
import {useAuth} from '../modules/auth'
import {SuspenseView} from './AppRoutes'
import {SalesSignature} from '../modules/agreements'
import {FEATURES} from '../../_gori/constants/_features'

const PrivateRoutes = () => {
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const OrdersWrapper = lazy(() => import('../pages/orders/OrdersWrapper'))
  const BatchesWrapper = lazy(() => import('../pages/batches/BatchesWrapper'))
  const ShipmentsWrapper = lazy(() => import('../pages/shipments/ShipmentsWrapper'))
  const ClaimsWrapper = lazy(() => import('../pages/claims/ClaimsWrapper'))
  const ReportsWrapper = lazy(() => import('../pages/reports/ReportsWrapper'))
  const BaeWatchWrapper = lazy(() => import('../pages/baewatch/BaeWatchWrapper'))
  const DepositWrapper = lazy(() => import('../pages/deposit/DepositWrapper'))
  const SettingsWrapper = lazy(() => import('../pages/settings/SettingsWrapper'))
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const AdminWrapper = lazy(() => import('../pages/admin/AdminWrapper'))
  const EndOfDayWrapper = lazy(() => import('../pages/end-of-day/EndOfDayWrapper'))
  const {routes, firstPage} = UseRoutesPermission()
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        {routes.DASHBOARD.hasPermission && (
          <Route
            path='dashboard'
            element={
              <SuspenseView>
                <DashboardWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.ORDERS_VIEW.hasPermission && (
          <Route
            path='orders/*'
            element={
              <SuspenseView>
                <OrdersWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.SHIPMENTS_VIEW.hasPermission && (
          <Route
            path='shipments'
            element={
              <SuspenseView>
                <ShipmentsWrapper />
              </SuspenseView>
            }
          />
        )}
        {isFeatureEnabled(FEATURES.PDX) && routes.SHIPMENTS_VIEW.hasPermission && (
          <>
            <Route
              path='open-shipments'
              element={
                <SuspenseView>
                  <EndOfDayWrapper />
                </SuspenseView>
              }
            />
            <Route
              path='closed-shipments'
              element={
                <SuspenseView>
                  <EndOfDayWrapper />
                </SuspenseView>
              }
            />
          </>
        )}
        {routes.BATCHES_VIEW.hasPermission && (
          <Route
            path='batches/*'
            element={
              <SuspenseView>
                <BatchesWrapper />
              </SuspenseView>
            }
          />
        )}
        {isFeatureEnabled(FEATURES.CLAIMS) && routes.CLAIMS_VIEW.hasPermission && (
          <Route
            path='claims/*'
            element={
              <SuspenseView>
                <ClaimsWrapper />
              </SuspenseView>
            }
          />
        )}
        {(routes.REPORTS_SHIPMENTS.hasPermission || routes.REPORTS_TRANSACTIONS.hasPermission) &&
          (isFeatureEnabled(FEATURES.SHIPMENT_REPORT) || isFeatureEnabled(FEATURES.METER)) && (
            <Route
              path='reports/*'
              element={
                <SuspenseView>
                  <ReportsWrapper />
                </SuspenseView>
              }
            />
          )}
        {isFeatureEnabled(FEATURES.DEPOSITS) && routes.DEPOSITS.hasPermission && (
          <Route
            path='/deposits'
            element={
              <SuspenseView>
                <DepositWrapper />
              </SuspenseView>
            }
          />
        )}
        {isFeatureEnabled(FEATURES.WARRANTY) && allowTestOnly(currentUser) && (
          <Route
            path='baewatch/*'
            element={
              <SuspenseView>
                <BaeWatchWrapper />
              </SuspenseView>
            }
          />
        )}
        <Route
          path='settings/*'
          element={
            <SuspenseView>
              <SettingsWrapper />
            </SuspenseView>
          }
        />
        {routes?.SYSTEM_ADMIN?.hasPermission && (
          <Route
            path='admin/*'
            element={
              <SuspenseView>
                <AdminWrapper />
              </SuspenseView>
            }
          />
        )}
        {isFeatureEnabled(FEATURES.SUPPORT_PAGE) && routes.SUPPORTS.hasPermission && (
          <Route
            path='support/*'
            element={
              <SuspenseView>
                <SupportWrapper />
              </SuspenseView>
            }
          />
        )}
        {/* Redirect to Dashboard for all pages that are not available */}
        <Route path='*' element={<Navigate to={firstPage?.route ?? '/support'} />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
