import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {STEPS} from '../core/_const'
import {KTSVG, toAbsoluteUrl} from '../../../../_gori/helpers'
import {useFormik} from 'formik'
import {Button} from '../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import * as Yup from 'yup'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {isEmpty} from 'lodash'
import {useAuth} from '../../auth'
import UserService from '../../users/core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import OnboardService from '../core/_requests'
import {ButtonLabelCreation} from '../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {useNavigate} from 'react-router-dom'

type Props = {
  setActiveStep: any
  onboard: any
  setOnboard: any
  setErrorValidate: any
}

const Hazmat: React.FC<Props> = ({setActiveStep, onboard, setOnboard, setErrorValidate}) => {
  const intl = useIntl()
  const {authYup} = UseYupValidate()
  const {currentUser, setCurrentUser} = useAuth()
  const [accepted, setAccepted] = useState(currentUser?.user_profiles?.hazmat?.check_hazardous)
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const nextStep = () => {
    setActiveStep(STEPS.profile)
  }

  const validationSchema = useMemo(() => {
    let userStep = STEPS[`${currentUser?.onboard_step}`] ?? 0
    let currentStep = STEPS[`hazmat`]
    let required = true
    if (currentStep === userStep && accepted) {
      required = false
    }
    return {
      check_hazardous: required ? authYup.acceptHazardousCheckbox : Yup.boolean(),
    }
  }, [accepted, authYup.acceptHazardousCheckbox, currentUser?.onboard_step])

  const initialValues = useMemo(() => {
    return {
      check_hazardous: currentUser?.user_profiles?.hazmat?.check_hazardous ?? false,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true)
      let payload = {
        step: 'hazmat',
        data: {
          ...values,
        },
        save_and_exit: false,
      }
      try {
        const config = {cancelToken: newCancelToken()}
        const res = await OnboardService.store(payload, config)
        if (res) {
          setCurrentUser(await UserService.getUser(config))
          let newOnboard = {
            hazmat: payload.data,
            profile: onboard.profile,
            shipping: onboard.shipping,
            ob_general: onboard.ob_general,
            ship_from_address: onboard.ship_from_address,
          }
          setOnboard(newOnboard)
          nextStep()
        }
      } catch (error: any) {
        setLoading(false)

        if (isCancel(error)) return
        setErrorValidate(error?.response)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    setErrorValidate(!isEmpty(formik.errors))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div
        className='px-10 d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundColor: '#E4EFEE',
          backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.png')})`,
        }}
      >
        <div className={'hazmat_content w-100 w-sm-75 w-lg-50'}>
          <p className='hazmat__header text-center mb-6'>
            {intl.formatMessage({
              id: 'USPS_UPDATE_ON_HAZMAT_HAZARDOUS_MATERIAL_SHIPPING_POLICY_OCTOBER_1ST',
            })}
          </p>
          <p
            className='hazmat__content mb-9'
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'EFFECTIVE_OCTOBER_1'})}}
          ></p>
          <p
            className='hazmat__blue m-0'
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'IN_ACCORDANCE'})}}
          ></p>
          <p
            className='hazmat__blue mb-9'
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'TO_LEARN'})}}
          ></p>
          <ol className='m-0'>
            <li className='hazmat__list'>
              {intl.formatMessage({id: 'HAZMAT_REFER_TO_FOLLOWING_DOCUMENTS_CONTENT_1'})}{' '}
              <a href='https://pe.usps.com/text/pub52/welcome.htm' target='_blank' rel='noreferrer'>
                {intl.formatMessage({id: 'USPS_PUBLICATION_52'})}
              </a>
            </li>
            <li className='hazmat__list'>
              {intl.formatMessage({id: 'HAZMAT_REFER_TO_FOLLOWING_DOCUMENTS_CONTENT_2'})}{' '}
              <a href='https://pe.usps.com/hazmat/index' target='_blank' rel='noreferrer'>
                {intl.formatMessage({id: 'HAZMAT_ITEMS_SEARCH_TOOL'})}
              </a>
            </li>
          </ol>

          <hr className='hazmat__hr my-6' />

          <p className='hazmat__blue mb-4'> {intl.formatMessage({id: 'BY_PROCEEDING'})}</p>
          <p
            className='hazmat__content m-0'
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'HAZMAT_DESCRIPTION'})}}
          ></p>
          <div className='hazmat__img m-0'>
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/battery.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/thermometer.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/spray.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/liquor.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/nail.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/paint.png')} alt='' />
            <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/fuel.png')} alt='' />
          </div>
          <p className='hazmat__content mb-1'>
            {intl.formatMessage({id: 'HAZMAT_CATEGORY_INCLUDE'})}
          </p>
          <a
            href='https://gori.ai/faq/?q=hazmat'
            target='_blank'
            rel='noreferrer'
            className='hazmat__link mb-0'
          >
            {intl.formatMessage({id: 'LEARN_MORE'})}
          </a>
          <p className='hazmat__blue mt-6 mb-4'>{intl.formatMessage({id: 'SENDING_DANGEROUS'})}</p>

          <ul className='hazmat__list mb-6'>
            <li>
              <strong className='d-inline-block w-25'>
                {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_1'})}
              </strong>
              <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_1'})}</span>
            </li>
            <li>
              <strong className='d-inline-block w-25'>
                {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_2'})}
              </strong>
              <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_2'})}</span>
            </li>
            <li>
              <strong className='d-inline-block w-25'>
                {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_3'})}
              </strong>
              <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_3'})}</span>
            </li>
            <li>
              <strong className='d-inline-block w-25'>
                {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_4'})}
              </strong>
              <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_4'})}</span>
            </li>
          </ul>

          <p
            className='hazmat__blue'
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'NOTE_PIN'})}}
          ></p>

          <ul>
            <li>
              <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_1'})}</em>
            </li>
            <li>
              <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_2'})}</em>
            </li>
            <li>
              <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_3'})}</em>
            </li>
          </ul>

          <hr className='hazmat__hr my-6' />

          <div className='hazmat__content mb-5'>{intl.formatMessage({id: 'HAZMAT_THANKS'})}</div>
          <div className='hazmat__content  mb-5'>
            {intl.formatMessage({id: 'THANK_YOU_FOR_YOUR_ATTENTION_TO_THIS_IMPORTANT_MATTER'})}
          </div>
          <div className='hazmat__content mb-1'>{intl.formatMessage({id: 'SINCERELY'})},</div>
          <img
            className='h-25px mb-12'
            src='/media/gori/hazmat/gori_company.png'
            alt='gori-company'
          />

          <div className='hazmat__content '>
            <div className='d-flex '>
              <InputCheckboxFormik
                formik={formik}
                className={'float-start'}
                labelClassName={'fs-6 required'}
                type='checkbox'
                checked={accepted}
                name='check_hazardous'
                label={intl.formatMessage({
                  id: 'I_HAVE_READ_AND_ACKNOWLEDGE_USPS_HAZMAT_SHIPPING_POLICY',
                })}
                onClick={() => setAccepted(!accepted)}
              />
            </div>
            {formik.errors.check_hazardous && formik.touched.check_hazardous && (
              <div className='text-danger'>
                {intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})}
              </div>
            )}
          </div>
          <div className='d-flex justify-content-center mt-10'>
            <Button
              className={'btn btn-primary'}
              loading={loading}
              label={intl.formatMessage({id: 'SUBMIT'})}
              event={() => formik.handleSubmit()}
              disabled={!accepted}
            />
          </div>
          <div className='d-flex justify-content-end mt-10'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'EXIT'})}
              loadingText={intl.formatMessage({id: 'EXIT'})}
              event={() => navigate('/logout')}
            ></ButtonLabelCreation>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export {Hazmat}
