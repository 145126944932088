import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, find, has, isEmpty, isEqual, last, omit, pick, some} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {REGEX_ALPHANUMERIC, SERVICES} from '../../../../../_gori/constants'
import {
  convertUserTimeZone,
  isUSMilitaries,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../../_gori/helpers'
import {OrderDetailSkeleton} from '../../../../../_gori/helpers/components'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../../_gori/hooks/UseRoutesPermission'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'
import {OPTION_ITEM_TYPE} from '../../../claims/core/_const'
import {
  AddressValidationModal,
  CustomsInternational,
  EditAddress,
  InsuranceSignature,
  ItemPackages,
  NoticeCreateLabelModal,
  OrderItems,
  PackageInfo,
  PresetApplyButton,
  Rates,
} from '../../../orders'
import ShipmentService from '../../../shipments/core/_requests'
import {OrdersConfig, OrderStatus, SORT_KEYS_ORDER_VALIDATE} from '../../core/_const'
import OrderHelper from '../../core/_helpers'
import OrderService from '../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess?: any
  parentId: any
  name?: 'createLabel' | 'editOrder'
}

const OrderChildModal: React.FC<Props> = ({
  show,
  handleClose,
  handleSuccess,
  parentId,
  name = 'createLabel',
}) => {
  const intl = useIntl()
  const orderHelper = OrderHelper()
  const {currentUser, currentCompany} = useAuth()
  const renderFirst = useRef(true)
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, orderYup, regexYup, numberYup, infoYup, dateYup} = UseYupValidate()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const [loading, setLoading] = useState<{
    first: boolean
    validate: boolean
    save: boolean
    ship: boolean
    rate: boolean
    updateRate: boolean
  }>({
    validate: false,
    first: true,
    save: false,
    ship: false,
    rate: false,
    updateRate: false,
  })
  const [data, setData] = useState<any>(null)
  const [showModal, setShowModal] = useState<{noticeCreateLabel: boolean}>({
    noticeCreateLabel: false,
  })
  const [action, setAction] = useState<'validate' | 'save' | 'ship' | undefined>()
  const [fromToModal, setFormToModal] = useState<any>({
    show: false,
    name: undefined,
    data: {},
    validate: {},
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const row = useRef({parcels: 0, order_items: 0, custom_items: 0})
  const {routes} = UseRoutesPermission()
  const [dataRates, setDataRates] = useState<any>()
  const [canSave, setCanSave] = useState<boolean>(!parentId)
  const [canGetRates, setCanGetRates] = useState<boolean>(false)
  const [checkValidateLeft, setCheckValidateLeft] = useState<boolean>(false)
  const currentValue = useRef<any>(null)
  const [showHide, setShowHide] = useState<{
    shipmentDetail: boolean
    custom: boolean
    orderDetail: boolean
  }>({shipmentDetail: false, custom: false, orderDetail: true})

  const [showValidationModal, setShowValidationModal] = useState(false)
  const [dataSuggest, setDataSuggest] = useState<any>()
  const currentRates = useRef<any>(null)
  const [scrollToErrors, setScrollToErrors] = useState<boolean>(false)

  const hasPermissionView =
    routes.ORDERS_UPDATE.hasPermission || routes.ORDERS_CREATE.hasPermission
      ? false
      : routes.ORDERS_VIEW.hasPermission

  const isStatusOpen = useMemo(() => {
    return data?.status === OrderStatus.OPEN
  }, [data?.status])

  const isStatusShipped = useMemo(() => {
    return data?.status === OrderStatus.SHIPPED
  }, [data?.status])

  const isDisablePage = useMemo(() => {
    return loading.save || loading.ship || loading.validate
  }, [loading.save, loading.ship, loading.validate])

  // BEGIN: validate
  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const validateItems = useMemo(() => {
    return Yup.object().shape({
      title: stringYup(255, 'NAME'),
      sku: stringYup(70, 'SKU', false),
      quantity: numberYup.quantity('QUANTITY', false),
      unit_price: numberYup.unitPrice('PRICE', false),
    })
  }, [numberYup, stringYup])

  const validateCustoms = useCallback(
    (name) => {
      const customsValidation = {
        content: orderYup.customs.content,
        content_description: orderYup.customs.content_description,
        signer: orderYup.customs.signer,
        description: orderYup.customs.description,
        hs_tariff_number: orderYup.customs.hsTariffNumber,
        country: orderYup.customs.country,
        code: orderYup.customs.code,
        quantity: orderYup.customs.quantity,
        value: orderYup.customs.value,
        weight: orderYup.customs.weight,
      }

      return customsValidation[name] || Yup.string().trim()
    },
    [orderYup.customs]
  )

  const initValidateSchema = useMemo(() => {
    return {
      from: Yup.object().shape(
        Object.entries(validateAddress('from')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['from_first_name', 'from_company']]
      ),
      to: Yup.object().shape(
        Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['to_first_name', 'to_company']]
      ),
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
              case 'weight':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
      ship_by_date: orderYup.shipByDate,
      international: Yup.boolean(),
      international_option: Yup.object().when('international', {
        is: (val) => val === true,
        then: Yup.object().shape({
          content: validateCustoms('content'),
          signer: validateCustoms('signer'),
          description: validateCustoms('content_description'),
        }),
      }),
      custom_items: Yup.object().when('international', {
        is: (val) => val === true,
        then: Yup.object().shape({
          [row.current.custom_items]: Yup.object().shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              initCustoms[value.value] = validateCustoms(value.value)
              return initCustoms
            }, {})
          ),
        }),
      }),
      check_weight: Yup.boolean().when('international', {
        is: (val) => val === true,
        then: orderYup.checkIntlWeight,
      }),
      service: stringYup(50, 'SERVICE', false),
      insurance_option: Yup.object().shape({
        insured_amount: orderYup.insuredAmount,
      }),
      reference1: orderYup.reference('REF_1'),
      reference2: orderYup.reference('REF_2'),
      claim_category: stringYup(255, 'MERCHANDISE_CATEGORY', false),
    }
  }, [numberYup, orderYup, stringYup, validateAddress, validateCustoms])

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)
  const validationSchema = Yup.object().shape(validateSchema, [['international', 'custom_items']])
  // END: validate

  const initValueEdit = useCallback(
    (name, init) => {
      let _data = cloneDeep(data)
      if (_data?.international_option?.custom_items) {
        _data.custom_items = _data?.international_option?.custom_items
      }

      if (data && _data?.[name]?.length >= 1) {
        let _valueName: any = {}
        let _validateName: any = {}

        for (let i = 0; i < _data?.[name]?.length; i++) {
          _valueName[row.current[name]] = _data?.[name][i]
          switch (name) {
            case 'order_items':
              _validateName[row.current[name]] = validateItems
              break
            case 'custom_items':
              _validateName[row.current[name]] = Yup.object()
                .default({})
                .shape(
                  Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                    initCustoms[value.value] = validateCustoms(value.value)
                    return initCustoms
                  }, {})
                )
              break
            default:
              _validateName[row.current[name]] = validateSchema[name].fields[0]
              break
          }

          if (i < _data?.[name]?.length - 1) {
            row.current[name] += 1
          }
        }

        init[name] = _valueName

        switch (name) {
          case 'custom_items':
            setValidateSchema((prev) => {
              return {
                ...prev,
                [name]: Yup.object().when('international', {
                  is: (val) => val === true,
                  then: Yup.object().shape({..._validateName}),
                }),
              }
            })
            break
          default:
            setValidateSchema((prev) => {
              return {
                ...prev,
                [name]: Yup.object().shape({..._validateName}),
              }
            })
            break
        }
      } else {
        switch (name) {
          case 'parcels':
            init[name] = {
              0: {
                package_id: null,
                package_type: '',
                length: null,
                width: null,
                height: null,
                weight: null,
                dimension_unit: 'in',
                weight_unit: 'oz',
              },
            }
            break
          case 'custom_items':
            init[name] = {
              0: {
                description: '',
                hs_tariff_number: '',
                country: '',
                code: '',
                quantity: '',
                value: '',
                weight: '',
              },
            }
            break

          default:
            break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] = data?.[`from_${value.value}`] || false
            initValue.to[`to_${value.value}`] = data?.[`to_${value.value}`] ?? true
            break
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            break
          default:
            initValue.from[`from_${value.value}`] = data?.[`from_${value.value}`] || ''
            initValue.to[`to_${value.value}`] = data?.[`to_${value.value}`] || ''
            break
        }
        return initValue
      },
      {from: {}, to: {}}
    )
    initValueEdit('parcels', init)
    init.ship_by_date =
      isEmpty(data?.ship_by_date) || data?.ship_by_date === 'Invalid date'
        ? undefined
        : data?.ship_by_date
    init.international = !!data?.international_option
    init.international_option = {
      content: data?.international_option?.content || null,
      signer: data?.international_option?.signer || null,
      description: data?.international_option?.description || null,
    }
    initValueEdit('custom_items', init)
    initValueEdit('order_items', init)
    init.estimated_rate = data?.estimated_rate || null
    init.is_cheapest_rate = data?.is_cheapest_rate || false
    init.delivery_option_id = data?.delivery_option_id || null
    init.service = data?.carrier && data?.service ? `${data?.carrier}_${data?.service}` : null
    const latestShipment: any = last(data?.shipments) || null
    init.service =
      data?.status === OrderStatus.SHIPPED
        ? latestShipment?.carrier && latestShipment?.service
          ? `${latestShipment?.carrier}_${latestShipment?.service}`
          : null
        : data?.carrier && data?.service
        ? `${data?.carrier}_${data?.service}`
        : null
    init.signature_confirmation = data?.signature_confirmation || null
    init.insurance_option = {
      insured_amount: data?.insurance_option?.insured_amount?.toString() || null,
      provider: data?.insurance_option?.provider || null,
    }
    init.preset_id = data?.preset_id || null
    init.check_weight = false
    init.reference1 = data?.reference1 || null
    init.reference2 = data?.reference2 || null
    init.claim_category = data?.claim_category || null

    return init
  }, [data, initValueEdit])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values = {
        ...values,
        ship_by_date:
          isEmpty(values.ship_by_date?.format()) || values.ship_by_date?.format() === 'Invalid date'
            ? undefined
            : values.ship_by_date?.format(),
      }

      if (action === 'save') {
        handleSaveOrders(values)
      }

      if (action === 'validate') {
        handleValidateAddress(values)
      }

      if (action === 'ship') {
        handleCreateLabel(values)
      }
    },
  })

  // BEGIN: Reset Validate
  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateSchema])
  // END: Reset Validate

  // BEGIN: initialize to initial value
  const getInitValue = useCallback(async () => {
    if (!parentId) return
    setLoading((prev) => ({...prev, first: true}))
    try {
      const res = await OrderService.get(parentId, {cancelToken: newCancelToken()})
      if (res) {
        const date = convertUserTimeZone(res.order?.ship_by_date, currentUser, '')
        setData({...res.order, ship_by_date: date})
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken, parentId])

  useEffect(() => {
    if (!parentId) return
    getInitValue()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInitValue, parentId])
  // END: initialize to initial value

  // BEGIN: Action Rate
  const handleGetRates = useCallback(async () => {
    setLoading((prev) => ({...prev, rate: true}))
    let canceled = false

    const dataParcels = orderHelper.buildRateParcels(
      formik?.values?.service,
      formik?.values?.parcels,
      false
    )

    currentRates.current = pick(formik.values, [
      'from',
      'to',
      'parcels',
      'international_option',
      'signature_confirmation',
      'insurance_option.insured_amount',
    ])

    try {
      const {rates} = await OrderService.rates(
        {
          shipment: {
            international_option: {
              ...formik.values?.international_option,
              custom_items: formik.values?.custom_items,
            },
            service_options: {
              confirmation: formik.values?.signature_confirmation,
              insured_amount: Number(formik.values?.insurance_option?.insured_amount),
            },
            from_address: Object.entries(OrdersConfig.SHIPPING).reduce((fromData, [key, value]) => {
              fromData[value.value] = formik.values?.from?.[`from_${value.value}`]
              return fromData
            }, {}),
            to_address: Object.entries(OrdersConfig.SHIPPING).reduce((toData, [key, value]) => {
              toData[value.value] = formik.values?.to?.[`to_${value.value}`]
              return toData
            }, {}),
            parcel: dataParcels,
          },
        },
        {cancelToken: newCancelToken()}
      )

      if (!canceled) {
        let _chooseRate: any = {}
        if (formik.values?.service) {
          _chooseRate = cloneDeep(rates).find((item: any) => {
            return item?.matching_package && item?.carrier_service === formik.values?.service
          })

          if (!_chooseRate) {
            _chooseRate = cloneDeep(rates).find((item) => {
              return item?.carrier_service === formik.values?.service
            })
          }
        }

        if (_chooseRate?.carrier_service !== formik.values?.service) {
          formik.setFieldValue('service', '')
          formik.setFieldValue('estimated_rate', null)
          formik.setFieldValue('is_cheapest_rate', false)
        }

        setDataRates({
          chooseRate: _chooseRate,
          options: rates,
        })
      }
    } catch (error: any) {
      if (isCancel(error)) {
        canceled = true
        return
      }
      setDataRates({
        chooseRate: null,
        options: [],
      })
      setValidationErrors(error?.response)
    } finally {
      if (!canceled) {
        setLoading((prev) => ({...prev, rate: false}))
      }
    }
  }, [formik, isCancel, newCancelToken, orderHelper])

  useEffect(() => {
    const values = pick(formik.values, [
      'from',
      'to',
      'parcels',
      'international_option',
      'signature_confirmation',
      'insurance_option.insured_amount',
    ])
    const validateParcels = ['length', 'width', 'height', 'weight'].every(
      (item) => Number(formik.values.parcels[0]?.[item]) > 0
    )
    const errors = Object.keys(formik.errors).map((item) => item)
    const fillDataRequired = !errors.some((item) =>
      [
        'from',
        'to',
        'parcels',
        'international_option',
        'signature_confirmation',
        'insurance_option',
      ].includes(item)
    )
    const _canGetRates = validateParcels && fillDataRequired
    setCanGetRates(_canGetRates)

    let timer
    const delayedHandleGetRates = () => {
      timer = setTimeout(() => {
        if (
          (!isEqual(currentRates.current, values) && _canGetRates && !loading.first) ||
          (!isEqual(currentRates.current, values) && _canGetRates && !parentId)
        ) {
          handleGetRates()
        }
      }, 1000)
    }
    delayedHandleGetRates()

    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRates.current, formik.values, handleGetRates])

  useEffect(() => {
    const allValue = cloneDeep({
      ...formik.values,
      parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])},
    })
    currentValue.current = pick(allValue, [
      'from',
      'to',
      'parcels',
      'reference1',
      'reference2',
      'claim_category',
      'ship_by_date',
      'order_items',
      'custom_items',
      'international_option',
      'signature_confirmation',
      'insurance_option',
    ])
    if (parentId) {
      renderFirst.current = false
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValues])

  const handleEditRate = useCallback(
    async (chooseRate) => {
      setLoading((prev) => ({...prev, updateRate: true}))
      try {
        const res = await OrderService.patch(
          {
            type: 'service',
            order_ids: [parentId],
            fields: {
              service: chooseRate?.carrier_service,
              package_type: chooseRate?.package_type?.value ?? null,
              estimated_rate: chooseRate?.rate ?? null,
              is_cheapest_rate: chooseRate?.cheapest ?? false,
              preset_id:
                chooseRate?.carrier_service === formik.values.service &&
                chooseRate?.package_type?.value === formik.values.parcels?.[0]?.package_type
                  ? formik?.values?.preset_id
                  : null,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, updateRate: false}))
      }
    },
    [
      formik.values?.parcels,
      formik.values?.preset_id,
      formik.values?.service,
      isCancel,
      newCancelToken,
      parentId,
    ]
  )

  const handleClickRate = (rate) => {
    formik.setValues({
      ...formik.values,
      signature_confirmation: null,
      insurance_option: {
        insured_amount: null,
        provider: null,
      },
      service: rate?.carrier_service || '',
      estimated_rate: rate?.rate || null,
      is_cheapest_rate: rate?.cheapest || false,
    })
    setDataRates((prev) => ({
      ...prev,
      chooseRate: rate,
    }))
    if (parentId && !isStatusShipped) {
      handleEditRate(rate)
    }
  }
  // END: Action Rate

  // BEGIN: Detail Order
  const handleSaveOrders = useCallback(
    async (values) => {
      try {
        currentValue.current = pick(
          {...formik.values, parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])}},
          [
            'from',
            'to',
            'parcels',
            'reference1',
            'reference2',
            'claim_category',
            'ship_by_date',
            'order_items',
            'custom_items',
            'international_option',
            'signature_confirmation',
            'insurance_option',
          ]
        )

        setLoading((prev) => ({...prev, save: true}))

        const dataParcels = orderHelper.buildParcels(cloneDeep(values?.parcels))
        const internationalOption = orderHelper.buildInternationalOption(
          values?.international_option,
          values?.custom_items
        )
        const res = await OrderService.update(
          parentId,
          {
            fields: {
              service: values.service ?? null,
              estimated_rate: values?.estimated_rate ?? null,
              is_cheapest_rate: values?.is_cheapest_rate ?? false,
              preset_id: values?.preset_id ?? null,
              signature_confirmation: values.signature_confirmation ?? null,
              insurance_option: carriers[values?.service]?.options?.insurance?.enabled
                ? values.insurance_option
                : null,
              reference1: values.reference1,
              reference2: values.reference2,
              ship_by_date: values.ship_by_date,
              order_items: values?.order_items,
              international_option: values.international ? internationalOption : null,
              claim_category: values?.claim_category || null,
              ...values.from,
              ...values.to,
              ...dataParcels,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
          switch (name) {
            case 'createLabel':
              toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
              handleGetRates()
              break
            case 'editOrder':
              handleSuccess()
              break
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, save: false}))
        setAction(undefined)
      }
    },
    [
      carriers,
      formik.values,
      handleGetRates,
      handleSuccess,
      intl,
      isCancel,
      name,
      newCancelToken,
      orderHelper,
      parentId,
    ]
  )

  const handleCreateLabel = useCallback(
    async (values) => {
      const _valueForm = cloneDeep(values)
      setLoading((prev) => ({...prev, ship: true}))

      const dataParcels = orderHelper.buildParcels(cloneDeep(_valueForm?.parcels))
      const internationalOption = orderHelper.buildInternationalOption(
        _valueForm?.international_option,
        _valueForm?.custom_items
      )

      const isReship = data?.status === OrderStatus.SHIPPED
      const payload = {
        id: data?.id,
        store_type: data?.store_type,
        store_id: data?.store_id,
        fields: {
          service: _valueForm.service,
          preset_id: values?.preset_id ?? null,
          delivery_option_id: _valueForm.delivery_option_id ?? null,
          signature_confirmation: _valueForm.signature_confirmation ?? null,
          insurance_option: carriers[_valueForm?.service]?.options?.insurance?.enabled
            ? _valueForm.insurance_option
            : null,
          reference1: _valueForm?.reference1,
          reference2: _valueForm?.reference2,
          ship_by_date:
            isEmpty(_valueForm?.ship_by_date) || _valueForm?.ship_by_date === 'Invalid date'
              ? null
              : _valueForm?.ship_by_date,
          order_items: _valueForm?.order_items,
          international_option: _valueForm.international ? internationalOption : null,
          claim_category: _valueForm?.claim_category || null,
          ..._valueForm.from,
          ..._valueForm.to,
          ...dataParcels,
        },
      }

      try {
        const response = isReship
          ? await ShipmentService.reship(payload, {cancelToken: newCancelToken()})
          : await ShipmentService.store(payload, {cancelToken: newCancelToken()})
        if (response) {
          const navigateUrl = parentId ? '/orders?&status=' + OrderStatus.SHIPPED : null
          handleSuccess(response.label_url, navigateUrl)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, ship: false}))
        setAction(undefined)
      }
    },
    [
      carriers,
      data?.id,
      data?.store_id,
      data?.store_type,
      data?.status,
      handleSuccess,
      isCancel,
      parentId,
      newCancelToken,
      orderHelper,
    ]
  )
  // END: Detail Order

  // BEGIN: Items and Package action
  const handleAddNewItem = useCallback(
    (name) => {
      let newItem
      switch (name) {
        case 'order_items':
          newItem = {
            [row.current[name] + 1]: {
              sku: '',
              title: '',
              quantity: '',
              unit_price: '',
            },
          }
          break

        case 'parcels':
          newItem = {
            [row.current[name] + 1]: {
              length: '',
              width: '',
              height: '',
              weight: '',
              dimension_unit: null,
              weight_unit: null,
            },
          }
          break

        case 'custom_items':
          newItem = {
            [row.current[name] + 1]: {
              description: '',
              hs_tariff_number: '',
              country: '',
              code: '',
              quantity: '',
              value: '',
              weight: '',
            },
          }
          break

        default:
          break
      }

      formik.setFieldValue(name, {...formik.values[name], ...newItem})

      let keyLast = row.current[name].toString()
      const validate = !isEmpty(formik.values?.[name])
        ? Object.entries(formik.values?.[name]).reduce((init, [key, value]) => {
            switch (name) {
              case 'order_items':
                init[key] = validateItems
                break
              case 'custom_items':
                init[key] = Yup.object()
                  .default({})
                  .shape(
                    Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce(
                      (initCustoms, [key, value]) => {
                        switch (value.value) {
                          case 'country':
                            initCustoms[value.value] =
                              formik.values?.service === SERVICES.DHL_EXPRESS
                                ? stringYup(255, 'COUNTRY_OF_ORIGIN')
                                : validateCustoms(value.value)
                            break
                          default:
                            initCustoms[value.value] = validateCustoms(value.value)
                            break
                        }
                        return initCustoms
                      },
                      {}
                    )
                  )
                break
              default:
                init[key] = validateSchema[name].fields[0]
                break
            }
            keyLast = key
            return init
          }, {})
        : {}

      switch (name) {
        case 'order_items':
          validate[Number(keyLast) + 1] = validateItems
          break
        case 'custom_items':
          validate[Number(keyLast) + 1] = Yup.object()
            .default({})
            .shape(
              Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                switch (value.value) {
                  case 'country':
                    initCustoms[value.value] =
                      formik.values?.service === SERVICES.DHL_EXPRESS
                        ? stringYup(255, 'COUNTRY_OF_ORIGIN')
                        : validateCustoms(value.value)
                    break
                  default:
                    initCustoms[value.value] = validateCustoms(value.value)
                    break
                }
                return initCustoms
              }, {})
            )
          break
        default:
          validate[Number(keyLast) + 1] = validateSchema[name].fields[0]
          break
      }

      switch (name) {
        case 'custom_items':
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().when('international', {
                is: (val) => val === true,
                then: Yup.object().default({}).shape(validate),
              }),
            }
          })
          break
        default:
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().default({}).shape(validate),
            }
          })
          break
      }

      row.current = {...row.current, [name]: (row.current[name] += 1)}
    },
    [formik, stringYup, validateCustoms, validateItems, validateSchema]
  )

  const handleDeleteItem = useCallback(
    (name, keyDelete) => {
      const objDelete = omit(formik.values[name], [keyDelete])
      formik.setFieldValue(name, objDelete)
      const validate = Object.entries(objDelete).reduce((init, [key, value]) => {
        switch (name) {
          case 'order_items':
            init[key] = validateItems
            break
          case 'custom_items':
            init[key] = Yup.object()
              .default({})
              .shape(
                Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                  initCustoms[value.value] = validateCustoms(value.value)
                  return initCustoms
                }, {})
              )
            break
          default:
            init[key] = validateSchema[name].fields[0]
            break
        }
        return init
      }, {})

      switch (name) {
        case 'custom_items':
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().when('international', {
                is: (val) => val === true,
                then: Yup.object().shape({...validate}),
              }),
            }
          })
          break
        default:
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().shape(validate),
            }
          })
          break
      }
    },
    [formik, validateCustoms, validateItems, validateSchema]
  )
  // END: Items and Package action

  // BEGIN: Validate depends on service
  useEffect(() => {
    if (formik.values.international) {
      const customs = {}
      const customsRequiredCountry = {}
      Object.keys(formik.values.custom_items).forEach((key) => {
        customs[key] = Yup.object()
          .default({})
          .shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              initCustoms[value.value] = validateCustoms(value.value)
              return initCustoms
            }, {})
          )
        customsRequiredCountry[key] = Yup.object()
          .default({})
          .shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              switch (value.value) {
                case 'country':
                  initCustoms[value.value] = stringYup(255, 'COUNTRY_OF_ORIGIN')
                  break
                default:
                  initCustoms[value.value] = validateCustoms(value.value)
                  break
              }
              return initCustoms
            }, {})
          )
      })

      const custom_items = Yup.object().when('international', {
        is: (val) => val === true && formik.values.service === SERVICES.DHL_EXPRESS,
        then: Yup.object().default({}).shape(customsRequiredCountry),
        otherwise: Yup.object().default({}).shape(customs),
      })

      setValidateSchema((prev) => {
        return {
          ...prev,
          custom_items,
        }
      })
    }

    if (formik.values.service === SERVICES.UPS_MI) {
      setValidateSchema((prev) => {
        return {
          ...prev,
          reference1: orderYup
            .reference('REF_1', true)
            .matches(
              REGEX_ALPHANUMERIC,
              intl.formatMessage(
                {id: 'INPUT_ALPHANUMERIC'},
                {input: intl.formatMessage({id: 'REF_1'})}
              )
            ),
        }
      })
    } else {
      setValidateSchema((prev) => {
        return {
          ...prev,
          reference1: orderYup.reference('REF_1'),
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.service])
  // END: Validate depends on service

  // BEGIN: remove validate international when change country
  useEffect(() => {
    if (!formik.values.international) {
      setValidateSchema((prev) => ({...prev, custom_items: initValidateSchema.custom_items}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.international])
  // END: remove validate international when change country

  // BEGIN: Validate parcel when change package type
  useEffect(() => {
    setValidateSchema((prev) => ({
      ...prev,
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              case 'weight':
                initParcel[currentName] = numberYup.parcel(
                  currentName,
                  true,
                  formik.values.parcels[0]?.package_type ? 'hasPackage' : ''
                )
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.parcels[0]?.package_type])
  // END: Validate parcel when change package type

  // BEGIN: Check international
  useEffect(() => {
    const toCountry = formik.values?.to?.to_country?.trim()
    const toState = formik.values?.to?.to_state?.trim()
    const toCity = formik.values?.to?.to_city?.trim()
    const fromCountry = formik.values?.from?.from_country?.trim()
    const fromState = formik.values?.from?.from_state?.trim()
    const fromCity = formik.values?.from?.from_city?.trim()

    if ((toCountry && toCountry !== 'US') || (fromCountry && fromCountry !== 'US')) {
      formik.setFieldValue('international', true)
    } else {
      if (
        OrdersConfig.US_TERRITORIES.includes(toState) ||
        OrdersConfig.US_TERRITORIES.includes(fromState)
      ) {
        formik.setFieldValue('international', true)
      } else if (isUSMilitaries(toCity, fromCity)) {
        formik.setFieldValue('international', true)
      } else {
        formik.setFieldValue('international', false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [
    formik.values.from.from_country,
    formik.values.from.from_state,
    formik.values.from.from_city,
    formik.values.to.to_country,
    formik.values.to.to_state,
    formik.values.to.to_city,
  ])
  // END: Check international

  // BEGIN: Validate has can save
  useEffect(() => {
    const dataParcels = pick(
      {...formik.values, parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])}},
      [
        'from',
        'to',
        'parcels',
        'reference1',
        'reference2',
        'claim_category',
        'ship_by_date',
        'order_items',
        'custom_items',
        'international_option',
        'signature_confirmation',
        'insurance_option',
      ]
    )
    if (parentId && (isEqual(dataParcels, currentValue.current) || !currentValue.current)) {
      return setCanSave(false)
    }
    setCanSave(checkValidateLeft)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidateLeft, formik.values, currentValue.current, parentId])

  useEffect(() => {
    const result = some(
      [
        'from',
        'to',
        'parcels',
        'custom_items',
        'international_option',
        'order_items',
        'check_weight',
      ],
      (key) => has(formik.errors, key)
    )
    if (checkValidateLeft !== !result) {
      setCheckValidateLeft(!result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])
  // END: Validate has can save

  // BEGIN: Notice about package type & service in Create Label
  const serviceHasPackage = useMemo(() => {
    if (!formik.values.parcels[0]?.package_type || !formik.values?.service) return true
    const {package_types} = carriers?.[formik.values.service]
    if (package_types) {
      return !!find(package_types, {value: formik.values?.parcels?.[0]?.package_type})
    }
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, formik.values.parcels?.[0]?.package_type, formik.values.service])

  const handleCreateLabelModal = () => {
    setShowModal((prev) => ({...prev, noticeCreateLabel: false}))
    setAction('ship')
    setScrollToErrors(true)
    formik.handleSubmit()
  }
  // END: Notice about package type & service in Create Label

  // BEGIN: JUST MODAL
  const handleCloseModal = useCallback(() => {
    handleClose()
    setAction(undefined)
    formik.resetForm()
    row.current = {parcels: 0, order_items: 0, custom_items: 0}
  }, [formik, handleClose])

  const handleUseSuggested = (key) => {
    const updateValue = OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
      initValue[`${key}_${current}`] = dataSuggest[key][current]
      return initValue
    }, {})
    updateValue[`${key}_is_verified`] = true

    formik.setFieldValue(key, {...formik.getFieldProps(key).value, ...updateValue})
  }

  const handleValidateAddress = useCallback(
    async (values) => {
      setLoading((prev) => ({...prev, validate: true}))
      try {
        const [valuePayloadFrom, valuePayloadTo] = ['from', 'to'].map((item) =>
          OrdersConfig.VALIDATE_ADDRESS.reduce((valueCurrent, current) => {
            valueCurrent[current] = values[item][`${item}_${current}`]
            return valueCurrent
          }, {})
        )

        const res = await Promise.all([
          OrderService.validateAddress(
            {address: valuePayloadFrom},
            {cancelToken: newCancelToken()}
          ),
          OrderService.validateAddress({address: valuePayloadTo}, {cancelToken: newCancelToken()}),
        ])
        const [valueSuggestFrom, valueSuggestTo] = [0, 1].map((index) =>
          OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
            initValue[current] = res[index][current]
            return initValue
          }, {})
        )
        formik.setFieldValue(
          'from.from_is_verified',
          isEqual(valuePayloadFrom, valueSuggestFrom) &&
            res[0].verifications.delivery.success &&
            res[0].verifications.zip4.success
        )
        formik.setFieldValue(
          'to.to_is_verified',
          isEqual(valuePayloadTo, valueSuggestTo) &&
            res[1].verifications.delivery.success &&
            res[1].verifications.zip4.success
        )

        setDataSuggest({from: res[0], to: res[1]})
        setShowValidationModal(true)
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, validate: false}))
        setAction(undefined)
      }
    },
    [formik, isCancel, newCancelToken]
  )
  // END: JUST MODAL

  // BEGIN: Scroll to errors
  const getErrorPaths = (errors: Record<string, any>, prefix = ''): string[] => {
    return Object.keys(errors).reduce<string[]>((paths, key) => {
      const currentPath = prefix ? `${prefix}.${key}` : key
      const value = errors[key]

      if (key === 'to' || key === 'from') {
        paths.push(currentPath)
        return paths
      }

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (item !== null && typeof item === 'object') {
              paths.push(...getErrorPaths(item, `${currentPath}.${index}`))
            }
          })
        } else {
          paths.push(...getErrorPaths(value, currentPath))
        }
      } else {
        paths.push(currentPath)
      }

      return paths
    }, [])
  }

  const getKeyOrder = (path: string) => {
    const key = path.split('.')[0]
    return SORT_KEYS_ORDER_VALIDATE.indexOf(key)
  }

  useEffect(() => {
    const errorPaths = getErrorPaths(formik.errors)

    if (errorPaths.length > 0 && scrollToErrors) {
      const firstErrorPath = errorPaths.sort((a, b) => getKeyOrder(a) - getKeyOrder(b))[0]
      const modal = document.getElementById('gori_modal_create_label') as HTMLElement
      const input: any = modal.querySelector(`[name="${firstErrorPath}"]`) as HTMLElement

      if (input) {
        const elementToScroll = input.type === 'hidden' ? input.closest('div') : input

        if (elementToScroll) {
          setTimeout(() => {
            elementToScroll.scrollIntoView({behavior: 'smooth', block: 'center'})
            if (elementToScroll.type !== 'hidden') {
              elementToScroll.focus()
            }
          }, 0)
          setScrollToErrors(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToErrors, formik.errors])
  // END: Scroll to errors

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      {showModal.noticeCreateLabel && (
        <NoticeCreateLabelModal
          show={showModal.noticeCreateLabel}
          handleClose={() => setShowModal((prev) => ({...prev, noticeCreateLabel: false}))}
          handleCreateLabel={handleCreateLabelModal}
          name={data?.status === OrderStatus.SHIPPED ? 'RESHIP' : 'CREATE_LABEL'}
        />
      )}

      {showValidationModal && (
        <AddressValidationModal
          show={showValidationModal}
          handleCloseValidation={() => setShowValidationModal(false)}
          handleUseSuggested={handleUseSuggested}
          dataSuggest={dataSuggest}
          dataOriginal={formik.values}
        />
      )}

      <Modal
        id='gori_modal_create_label'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {parentId && loading.first ? (
                <span className='fs-1 w-200px placeholder placeholder-lg rounded-2 bg-secondary me-2' />
              ) : (
                (() => {
                  let labelModal: string | null = null
                  switch (name) {
                    case 'editOrder':
                      labelModal = 'EDIT_ORDER'
                      break
                    case 'createLabel':
                      labelModal = data.status === OrderStatus.SHIPPED ? 'RESHIP' : 'SHIP'
                      break
                  }
                  return intl.formatMessage({id: labelModal})
                })()
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='px-4'>
              <>
                {parentId && loading.first ? (
                  <OrderDetailSkeleton />
                ) : (
                  (() => {
                    let children = null
                    if (data) {
                      children = data?.children
                        .map((obj) => `${currentCompany?.sb_code}-${obj.code}`)
                        .join(', ')
                    }

                    return (
                      <div>
                        <div className='row col-12'>
                          <div className='scroll-y vh-75 z-index-1 col-12 col-xl-7'>
                            <div
                              className={clsx('bg-white rounded-2 border border-primary p-6', {
                                'bg-light cursor-no-drop': isDisablePage,
                              })}
                            >
                              <div className='d-flex justify-content-between align-items-center'>
                                <span className='fs-2 fw-bolder required'>
                                  {intl.formatMessage({id: 'SHIPMENT_DETAIL'})}
                                </span>
                                <PresetApplyButton
                                  formik={formik}
                                  disabled={isDisablePage}
                                  fromCreateOrder={false}
                                  dataCopy={true}
                                  setDataRates={setDataRates}
                                  renderFirst={renderFirst}
                                />
                              </div>
                              <div>
                                <hr className='bg-gray-600' />
                                <div>
                                  <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <h3 className='fw-bolder fs-2'>
                                        {intl.formatMessage({id: 'SHIP_FROM'})}
                                      </h3>
                                      <div
                                        className='d-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer'
                                        onClick={() => {
                                          setFormToModal({
                                            show: true,
                                            name: 'from',
                                            data: formik.getFieldProps('from').value,
                                            validate: validateAddress('from'),
                                          })
                                        }}
                                      >
                                        <span className='fs-2 me-1'>+</span>
                                        {intl.formatMessage({
                                          id: Object.entries(
                                            formik.getFieldProps('from').value
                                          ).some(([key, value]) => !isEmpty(value))
                                            ? 'EDIT_ADDRESS'
                                            : 'NEW_ADDRESS',
                                        })}
                                      </div>
                                    </div>
                                    <EditAddress
                                      formik={formik}
                                      fromToModal={fromToModal}
                                      handleCloseFromToModal={() =>
                                        setFormToModal({
                                          show: false,
                                          name: undefined,
                                          data: {},
                                          validate: {},
                                        })
                                      }
                                      name='from'
                                      disabled={isDisablePage || hasPermissionView}
                                    />
                                  </div>
                                  <div className='mt-4'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <h3 className='fw-bolder fs-2'>
                                        {intl.formatMessage({id: 'SHIP_TO'})}
                                      </h3>
                                      <div
                                        className='d-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer'
                                        onClick={() => {
                                          setFormToModal({
                                            show: true,
                                            name: 'to',
                                            data: formik.values.to,
                                            validate: validateAddress('to'),
                                          })
                                        }}
                                      >
                                        <span className='fs-2 me-1'>+</span>
                                        {intl.formatMessage({
                                          id: Object.entries(formik.values.to).some(
                                            ([key, value]) => !isEmpty(value)
                                          )
                                            ? 'EDIT_ADDRESS'
                                            : 'NEW_ADDRESS',
                                        })}
                                      </div>
                                    </div>
                                    <EditAddress
                                      formik={formik}
                                      fromToModal={fromToModal}
                                      handleCloseFromToModal={() =>
                                        setFormToModal({
                                          show: false,
                                          name: undefined,
                                          data: {},
                                          validate: {},
                                        })
                                      }
                                      name='to'
                                      disabled={isDisablePage || hasPermissionView}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <PackageInfo
                                    formik={formik}
                                    disabled={isDisablePage || hasPermissionView}
                                  />
                                </div>
                                <div>
                                  <div className='d-flex flex-wrap gap-2 justify-content-between mt-10 mb-2'>
                                    <div className='fs-3 fw-bolder'>
                                      {intl.formatMessage({id: 'SHIPMENT_DATE'})}
                                    </div>
                                    <div
                                      className={clsx('d-flex col-12 col-sm-6', {
                                        'cursor-no-drop': isDisablePage || hasPermissionView,
                                      })}
                                    >
                                      <InputDate
                                        className={clsx('col-12', {'pe-none': isDisablePage})}
                                        labelClassName='col-3 col-form-label text-nowrap text-muted'
                                        name='ship_by_date'
                                        formik={formik}
                                        min={moment()}
                                        disabled={isDisablePage || hasPermissionView}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.values.international && (
                              <div className='mt-10'>
                                <CustomsInternational
                                  formik={formik}
                                  handleAddNewItem={handleAddNewItem}
                                  handleDeleteItem={handleDeleteItem}
                                  disabled={isDisablePage || hasPermissionView}
                                  isShippedPage={false}
                                  showHide={showHide}
                                  setShowHide={setShowHide}
                                />
                              </div>
                            )}
                            <div
                              className={clsx('bg-light rounded-2 mt-10 p-6', {
                                'bg-light cursor-no-drop': isDisablePage,
                              })}
                            >
                              <div className='d-flex justify-content-between align-items-center'>
                                <span className='fs-2 fw-bolder'>
                                  {intl.formatMessage({id: 'ORDER_DETAIL'})}
                                </span>
                              </div>
                              <div>
                                <hr className='bg-gray-600' />
                                <div className='d-flex'>
                                  <div className='col-6'>
                                    <label className='form-label text-muted col-4'>
                                      {intl.formatMessage({id: 'ORDER_DATE'})}
                                    </label>
                                    <span className='col-8 text-dark fw-bolder'>
                                      {!parentId
                                        ? moment().format(process.env.REACT_APP_DATE_FORMAT)
                                        : convertUserTimeZone(
                                            data?.created_at,
                                            currentUser,
                                            process.env.REACT_APP_DATE_FORMAT
                                          )}
                                    </span>
                                  </div>
                                  <div className='col-6'>
                                    <label className='form-label text-muted col-4'>BAE ID</label>
                                    <span className='col-8 text-dark fw-bolder'>
                                      {data && (
                                        <>
                                          <span className='fw-bolder text-dark'>
                                            {currentCompany?.sb_code}-{data?.code}
                                          </span>{' '}
                                          {data.children.length <= 0 && !isEmpty(data.split_from) && (
                                            <>
                                              <span className='m-1 badge badge-pill badge-light'>
                                                {intl.formatMessage({id: 'SPLIT'})}
                                              </span>
                                              <span className='fw-bolder text-dark'>
                                                {data?.split_from}
                                              </span>
                                            </>
                                          )}
                                          {children && (
                                            <>
                                              <span className='m-1 badge badge-pill badge-light'>
                                                {intl.formatMessage({id: 'COMBINED'})}
                                              </span>
                                              <span className='fw-bolder text-dark'>
                                                {children}
                                              </span>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div className={clsx('d-flex col-6 pe-6')}>
                                    <InputTextFormik
                                      className={clsx('col-8')}
                                      labelClassName='col-4 col-form-label text-nowrap text-muted'
                                      label={intl.formatMessage({id: 'REF_1'})}
                                      name='reference1'
                                      formik={formik}
                                      disabled={hasPermissionView || isDisablePage}
                                    />
                                  </div>
                                  <div className={clsx('d-flex col-6 ps-6')}>
                                    <InputTextFormik
                                      className={clsx('col-8')}
                                      labelClassName='col-4 col-form-label  text-nowrap text-muted'
                                      label={intl.formatMessage({id: 'REF_2'})}
                                      name='reference2'
                                      formik={formik}
                                      checkFormik={false}
                                      disabled={hasPermissionView || isDisablePage}
                                    />
                                  </div>
                                </div>
                                <div className='d-flex mt-4'>
                                  <SelectFormik
                                    className='col-8'
                                    labelClassName='col-4 col-form-label text-nowrap text-muted'
                                    label={intl.formatMessage({id: 'MERCHANDISE_CATEGORY'})}
                                    placeholder={intl.formatMessage(
                                      {id: 'SELECT_A_INPUT'},
                                      {input: intl.formatMessage({id: 'MERCHANDISE_CATEGORY'})}
                                    )}
                                    options={OPTION_ITEM_TYPE}
                                    formik={formik}
                                    name={'claim_category'}
                                    hasUseIntl={true}
                                    disabled={hasPermissionView || isDisablePage}
                                  />
                                </div>
                                <hr className='bg-gray-600' />
                                <ItemPackages
                                  formik={formik}
                                  data={data}
                                  handleAddNewItem={handleAddNewItem}
                                  handleDeleteItem={handleDeleteItem}
                                  disabled={
                                    isDisablePage ||
                                    hasPermissionView ||
                                    !isEmpty(data?.store_order_id)
                                  }
                                />
                              </div>
                            </div>
                            {!isEmpty(data?.children) && parentId && (
                              <OrderItems childItems={data?.children} disabled={isDisablePage} />
                            )}
                          </div>
                          <div className='col-12 col-xl-5 mt-4 mt-xl-0'>
                            <div
                              className={clsx('bg-white shadow-sm rounded-2 p-6', {
                                'bg-light': isDisablePage,
                              })}
                            >
                              <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                  <span className='fs-2 fw-bolder'>
                                    {intl.formatMessage({id: 'RATES'})}
                                  </span>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id='tooltip-disabled'>
                                        {intl.formatMessage({
                                          id: 'ESTIMATED_RATE_IS_EXCLUSIVE',
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <KTSVG
                                        path='/media/gori/orders/exclamation.svg'
                                        className='ms-2 mb-1 svg-icon-4 cursor-pointer'
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                                <Button
                                  className={clsx('btn btn-sm p-0', {
                                    'cursor-no-drop opacity-75': loading.rate || !canGetRates,
                                  })}
                                  event={() => canGetRates && handleGetRates()}
                                >
                                  <KTSVG
                                    path='/media/gori/orders/sync.svg'
                                    className='svg-icon-2'
                                    svgClassName={clsx({'spin text-primary': loading.rate})}
                                  />
                                  {intl.formatMessage({id: 'REFRESH'})}
                                </Button>
                              </div>
                              <hr className='bg-gray-600' />
                              <div>
                                {loading.rate && (
                                  <div className='h-400px d-flex justify-content-center align-items-center'>
                                    <img
                                      src={toAbsoluteUrl('/media/gori/orders/reload.gif')}
                                      className='w-150px'
                                      alt='reload-rate'
                                    />
                                  </div>
                                )}
                                {formik.values?.service &&
                                  isEmpty(dataRates) &&
                                  !loading.rate &&
                                  data?.status !== OrderStatus.SHIPPED &&
                                  Object.entries(carriers)?.map(
                                    ([keyService, valueService]: [string, any], index) => {
                                      if (keyService === formik.values?.service) {
                                        return (
                                          <div
                                            className={clsx(
                                              'btn border border-primary bg-light-primary d-flex justify-content-between my-5',
                                              {active: true}
                                            )}
                                            key={index}
                                          >
                                            <div className='d-flex align-items-center'>
                                              <div className='symbol symbol-40px me-5'>
                                                <span className='symbol-label bg-transparent'>
                                                  <img
                                                    src={valueService.visuals?.logo}
                                                    className='h-100 align-self-center'
                                                    alt='logo-shipment'
                                                  />
                                                </span>
                                              </div>
                                              <span className='text-gray-800 fs-6 fw-bolder'>
                                                {valueService.visuals?.display_name}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      }
                                      return null
                                    }
                                  )}
                                {dataRates && !loading.rate && (
                                  <Rates
                                    data={dataRates}
                                    handleClickRate={handleClickRate}
                                    disabled={loading.updateRate || isDisablePage}
                                  />
                                )}
                                <InsuranceSignature formik={formik} disabled={isDisablePage} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })()
                )}
              </>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {parentId && loading.first ? (
              <div className='d-flex flex-fill justify-content-between'>
                <div className='btn w-75px placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                <div className='btn w-75px placeholder placeholder-lg rounded-2 bg-secondary me-2' />
              </div>
            ) : (
              <div
                className={clsx('d-flex flex-fill', {
                  'justify-content-between': name === 'createLabel',
                  'justify-content-end': name !== 'createLabel',
                })}
              >
                <div
                  className={clsx('d-flex align-items-center', {
                    'flex-row-reverse': name !== 'createLabel',
                  })}
                >
                  {isStatusOpen &&
                    (name === 'editOrder' ? (
                      <>
                        <Button
                          className={clsx('btn btn-sm ms-4', {
                            'bg-primary text-white bg-hover-light-primary text-hover-primary':
                              canSave && checkValidateLeft,
                            'bg-secondary text-dark cursor-no-drop opacity-75':
                              !canSave || !checkValidateLeft,
                          })}
                          label={intl.formatMessage({id: 'SAVE'})}
                          loadingText={intl.formatMessage({id: 'SAVING'})}
                          event={() => {
                            setScrollToErrors(true)
                            formik.handleSubmit()
                            if (canSave) {
                              setAction('save')
                            }
                          }}
                          loading={loading.save}
                          disabled={loading.save || isDisablePage}
                        />
                        <Button
                          className={'btn btn-sm btn-secondary'}
                          label={intl.formatMessage({id: 'CANCEL'})}
                          loadingText={intl.formatMessage({id: 'CANCEL'})}
                          event={handleCloseModal}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          className={clsx('btn btn-sm', {
                            'bg-primary text-white bg-hover-light-primary text-hover-primary':
                              canSave && checkValidateLeft,
                            'bg-secondary text-dark cursor-no-drop opacity-75':
                              !canSave || !checkValidateLeft,
                          })}
                          label={intl.formatMessage({id: 'SAVE_CHANGES'})}
                          loadingText={intl.formatMessage({id: 'SAVING'})}
                          event={() => {
                            setScrollToErrors(true)
                            formik.handleSubmit()
                            if (canSave) {
                              setAction('save')
                            }
                          }}
                          loading={loading.save}
                          disabled={loading.save || isDisablePage}
                        />
                      </>
                    ))}
                </div>
                {name === 'createLabel' && (
                  <div className='d-flex justify-content-end'>
                    {parentId && routes.SHIPMENTS_CREATE.hasPermission && (
                      <>
                        <Button
                          className='btn btn-link text-decoration-underline fw-bolder me-4'
                          label={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                          loadingText={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                          loading={loading.validate}
                          disabled={loading.validate}
                          event={() => {
                            setAction('validate')
                            formik.submitForm()
                          }}
                        />
                        <Button
                          className={clsx('btn btn-sm', {
                            'bg-primary text-white bg-hover-light-primary text-hover-primary': !(
                              !checkValidateLeft || !formik.values.service
                            ),
                            'bg-secondary text-dark cursor-no-drop opacity-75':
                              !checkValidateLeft || !formik.values.service,
                          })}
                          label={intl.formatMessage({id: 'SHIP'})}
                          loadingText={intl.formatMessage({id: 'SHIP'})}
                          event={() => {
                            setScrollToErrors(true)
                            formik.handleSubmit()
                            if (!(!checkValidateLeft || !formik.values.service)) {
                              if (!serviceHasPackage) {
                                setShowModal((prev) => ({...prev, noticeCreateLabel: true}))
                              } else {
                                setAction('ship')
                              }
                            }
                          }}
                          loading={loading.ship}
                          disabled={
                            loading.ship || loading.rate || loading.save || !formik.values.service
                          }
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {OrderChildModal}
