import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, isEmpty, isEqual} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {isUSMilitaries} from '../../../../../_gori/helpers'
import {blockPopUp} from '../../../../../_gori/helpers/BrowserHelpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  SelectMultiLevelFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'
import ShipmentService from '../../../shipments/core/_requests'
import {EditShipAddress} from '../../components/EditShipAddress'
import {PackagesMenu} from '../../components/PackagesMenu'
import {
  OPTION_DIMENSION_UNIT,
  OPTION_PACKAGE_TYPE,
  OPTION_WEIGHT_UNIT,
  OrdersConfig,
} from '../../core/_const'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
  handleCreateReturnLabelSuccess: () => void
}

const CreateReturnLabelModal: React.FC<Props> = ({
  show,
  handleClose,
  data,
  handleCreateReturnLabelSuccess,
}) => {
  const intl = useIntl()
  const renderFirst = useRef(true)
  const optionCurrent = useRef<any>(null)
  const {statesGlobal, getPackages} = useStatesGlobal()
  const [internationalServiceUS, setInternationalServiceUS] = useState<{
    militaries: boolean
    territories: boolean
  }>({militaries: false, territories: false})
  const carriers = statesGlobal.carriers
  const packages = statesGlobal.packages
  const [optionsCustomPackage, setOptionsCustomPackage] = useState<any>([])
  const serviceCurrent = useRef(
    data?.carrier && data?.service ? `${data?.carrier}_${data?.service}` : null
  )
  const {currentCompany} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, orderYup, regexYup, numberYup, infoYup} = UseYupValidate()
  const [loading, setLoading] = useState<{
    create: boolean
  }>({
    create: false,
  })

  const [fromToModal, setFormToModal] = useState<any>({
    show: false,
    name: undefined,
    data: {},
    validate: {},
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const row = useRef({parcels: 0})

  // BEGIN: validate
  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: stringYup(255, 'PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const initValidateSchema = useMemo(() => {
    return {
      service: stringYup(50, 'SERVICE'),
      from: Yup.object().shape(
        Object.entries(validateAddress('from')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['from_first_name', 'from_company']]
      ),
      to: Yup.object().shape(
        Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['to_first_name', 'to_company']]
      ),
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
              case 'weight':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
    }
  }, [numberYup, stringYup, validateAddress])

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)
  const validationSchema = Yup.object().shape(validateSchema)
  // END: validate

  const initValueEdit = useCallback(
    (name, init) => {
      let _data = cloneDeep(data)

      if (data && _data?.[name]?.length >= 1) {
        let _valueName: any = {}
        let _validateName: any = {}

        for (let i = 0; i < _data?.[name]?.length; i++) {
          _valueName[row.current[name]] = _data?.[name][i]
          _validateName[row.current[name]] = validateSchema[name].fields[0]
          if (i < _data?.[name]?.length - 1) {
            row.current[name] += 1
          }
        }
        init[name] = _valueName
        setValidateSchema((prev) => {
          return {
            ...prev,
            [name]: Yup.object().shape({..._validateName}),
          }
        })
      } else {
        switch (name) {
          case 'parcels':
            init[name] = {
              0: {
                package_type: '',
                length: null,
                width: null,
                height: null,
                weight: null,
                dimension_unit: 'in',
                weight_unit: 'oz',
              },
            }
            break
          default:
            break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] = data?.[`to_${value.value}`] ?? false
            initValue.to[`to_${value.value}`] = data?.[`from_${value.value}`] ?? false
            break
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            initValue.from[`from_${value.value}`] = !!data?.[`to_${value.value}`]
            initValue.to[`to_${value.value}`] = !!data?.[`from_${value.value}`]
            break
          default:
            initValue.from[`from_${value.value}`] = data?.[`to_${value.value}`] ?? ''
            initValue.to[`to_${value.value}`] = data?.[`from_${value.value}`] ?? ''
            break
        }
        return initValue
      },
      {from: {}, to: {}}
    )
    initValueEdit('parcels', init)
    init.service = data?.carrier && data?.service ? `${data?.carrier}_${data?.service}` : null
    return init
  }, [data, initValueEdit])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        gori_shipment_id: data.gori_shipment_id,
        order_id: data?.order_id ?? null,
        payload: {
          service: values.service ?? null,
          reference1: data?.reference_1 ?? null,
          reference2: data?.reference_2 ?? null,
          international_option: data?.international_option ?? null,
          insurance_option: data?.insurance_option ?? null,
          signature_confirmation: data?.signature_confirmation ?? null,
          ordered_at: data?.ordered_at ?? null,
          ...values?.from,
          ...values?.to,
          ...values?.parcels[0],
        },
      }
      try {
        setLoading((prev) => ({...prev, create: true}))
        const response = await ShipmentService.printReturnLabel(payload, {
          cancelToken: newCancelToken(),
        })
        if (response) {
          if (blockPopUp(response.label_url)) {
            toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
          }
          handleCreateReturnLabelSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, create: false}))
      }
    },
  })

  const totalWeightPackage = useMemo(() => {
    let sum = 0
    Object.entries(formik.values.parcels).forEach(([key, value]: [any, any]) => {
      sum += Number.parseFloat(value?.weight) || 0
    })
    return sum
  }, [formik.values.parcels])

  // BEGIN: Options service and package
  useEffect(() => {
    getPackages()

    return () => {}
  }, [getPackages])

  useEffect(() => {
    if (!packages) {
      setOptionsCustomPackage([
        {
          parent: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
          current: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          options: [],
        },
      ])
    } else {
      const _optionPackages = packages.map((item) => {
        let objOption = {label: '', value: {}}
        objOption.label = item.name
        objOption.value = {
          length: item.length,
          width: item.width,
          height: item.height,
          dimension_unit: item.dimension_unit,
          package_id: item.id,
          package_type: '',
        }
        return objOption
      })
      setOptionsCustomPackage([
        {
          parent: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          current: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          options: _optionPackages,
        },
      ])
    }

    return () => {}
  }, [packages])

  const checkInternationalOption = useMemo(() => {
    const toCountry = formik.values?.to?.to_country?.trim()
    const toState = formik.values?.to?.to_state?.trim()
    const toCity = formik.values?.to?.to_city?.trim()
    const fromCountry = formik.values?.from?.from_country?.trim()
    const fromState = formik.values?.from?.from_state?.trim()
    const fromCity = formik.values?.from?.from_city?.trim()

    if ((toCountry && toCountry !== 'US') || (fromCountry && fromCountry !== 'US')) {
      setInternationalServiceUS({territories: false, militaries: false})
      formik.setFieldValue('international', true)
      return true
    } else {
      if (
        OrdersConfig.US_TERRITORIES.includes(toState) ||
        OrdersConfig.US_TERRITORIES.includes(fromState)
      ) {
        setInternationalServiceUS((prev) => ({...prev, territories: true}))
        formik.setFieldValue('international', true)
        return true
      } else {
        setInternationalServiceUS((prev) => ({...prev, territories: false}))
      }
      if (isUSMilitaries(toCity, fromCity)) {
        setInternationalServiceUS((prev) => ({...prev, militaries: true}))
        formik.setFieldValue('international', true)
        return true
      } else {
        setInternationalServiceUS((prev) => ({...prev, militaries: false}))
      }
      formik.setFieldValue('international', false)
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [
    formik.values.from.from_country,
    formik.values.from.from_state,
    formik.values.from.from_city,
    formik.values.to.to_country,
    formik.values.to.to_state,
    formik.values.to.to_city,
  ])

  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return

    const international = Object.values(cloneDeep(carriers)).filter(
      (value: any) => value?.international && value?.status
    )
    const noInternational = Object.values(cloneDeep(carriers)).filter(
      (value: any) => !value?.international && value?.status
    )
    const _internationalServiceUS = Object.values(cloneDeep(carriers)).filter((value: any) => {
      const carrierService = `${value.carrier}_${value.service}`
      if (!value?.international && value?.status) {
        if (
          internationalServiceUS.militaries &&
          OrdersConfig.SERVICE_FILTER.MILITARIES.includes(carrierService)
        ) {
          return value
        } else if (
          internationalServiceUS.territories &&
          OrdersConfig.SERVICE_FILTER.TERRITORIES.includes(carrierService)
        ) {
          return value
        }
      }
      return null
    })

    const _options = Object.values(
      checkInternationalOption
        ? internationalServiceUS.militaries || internationalServiceUS.territories
          ? _internationalServiceUS
          : international
        : noInternational
    ).map((value: any) => {
      value.label = value.visuals?.display_name
      value.value = `${value.carrier}_${value.service}`
      return value
    })

    return [
      {
        label: intl.formatMessage({
          id: checkInternationalOption
            ? internationalServiceUS.militaries || internationalServiceUS.territories
              ? 'DOMESTIC'
              : 'INTERNATIONAL'
            : 'DOMESTIC',
        }),
        options: _options,
      },
    ]
  }, [carriers, checkInternationalOption, internationalServiceUS, intl])

  useEffect(() => {
    if (renderFirst.current) {
      renderFirst.current = false
      optionCurrent.current = optionsService
      return
    }

    if (!isEqual(optionCurrent.current, optionsService)) {
      formik.setFieldValue('service', '')
      optionCurrent.current = optionsService
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsService])

  const optionsPackageTypes = useMemo(() => {
    const packageType = formik.values.parcels[0].package_type
    if (
      typeof packageType === 'string' &&
      packageType &&
      serviceCurrent.current !== formik.values.service
    ) {
      const initObj = {
        ...formik.values.parcels[0],
        length: '',
        width: '',
        height: '',
        dimension_unit: 'in',
        package_type: '',
        package_id: null,
      }
      formik.setFieldValue('parcels.0', initObj)
    }
    serviceCurrent.current = formik.values.service

    const _option = carriers[formik.values?.service]?.package_types
    if (isEmpty(_option)) return []

    return [
      {
        parent: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
        current: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
        options: _option.map((item: any) => {
          return {
            label: item.label,
            value: {...item.parcel, package_type: item.value, package_id: null},
          }
        }),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, formik.values?.service])

  const inputDimensions = useMemo(() => {
    return [
      {
        parent: OPTION_PACKAGE_TYPE.INPUT_DIMENSION,
        current: OPTION_PACKAGE_TYPE.INPUT_DIMENSION,
        options: [],
      },
    ]
  }, [])

  useMemo(() => {
    if (typeof formik.values?.parcels[0]?.package_type === 'object') {
      formik.setFieldValue('parcels.0', {
        ...formik.values.parcels[0],
        ...formik.values.parcels[0].package_type,
      })
    } else if (formik.values?.parcels[0]?.package_type) {
      formik.setFieldValue('parcels.0.package_id', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.parcels[0]?.package_type])
  // END: Options service and package

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_create_return_label'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto mw-1400px'
        show={show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix='fw-bolder fs-1'>
              {intl.formatMessage({id: 'CREATE_RETURN_LABEL'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='px-4'>
              {(() => {
                return (
                  <div className='row'>
                    <div className='d-flex mb-2 row'>
                      <h2 className='fw-bolder text-dark'>
                        {intl.formatMessage({id: 'ORDER_RETURN_DETAIL'})}
                      </h2>
                      <div className='align-middle'>
                        {data?.code && (
                          <>
                            {intl.formatMessage({id: 'ORDER'})}{' '}
                            <span className='fw-bolder text-dark'>
                              {currentCompany?.sb_code}-{data?.code}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-15 col-xxl-8 pe-xxl-7'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='bg-light rounded p-8 h-100'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <h3 className='fw-bolder fs-2'>
                                {intl.formatMessage({id: 'SHIP_FROM_ADDRESS'})}
                              </h3>
                              <div
                                className='d-flex align-items-center text-primary fw-bolder cursor-pointer'
                                onClick={() => {
                                  setFormToModal({
                                    show: true,
                                    name: 'from',
                                    data: formik.values.from,
                                    validate: validateAddress('from'),
                                  })
                                }}
                              >
                                <span className='fs-2 me-1'>+</span>
                                {intl.formatMessage({
                                  id: Object.entries(formik.values.from).some(
                                    ([key, value]) => !isEmpty(value)
                                  )
                                    ? 'EDIT_ADDRESS'
                                    : 'NEW_ADDRESS',
                                })}
                              </div>
                            </div>
                            <EditShipAddress
                              formik={formik}
                              fromToModal={fromToModal}
                              handleCloseFromToModal={() =>
                                setFormToModal({
                                  show: false,
                                  name: undefined,
                                  data: {},
                                  validate: {},
                                })
                              }
                              name='from'
                              hasReturn
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='bg-light rounded p-8 h-100'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <h3 className='fw-bolder fs-2'>
                                {intl.formatMessage({id: 'SHIP_TO_ADDRESS'})}
                              </h3>
                              <div
                                className='d-flex align-items-center text-primary fw-bolder cursor-pointer'
                                onClick={() => {
                                  setFormToModal({
                                    show: true,
                                    name: 'to',
                                    data: formik.values.to,
                                    validate: validateAddress('to'),
                                  })
                                }}
                              >
                                <span className='fs-2 me-1'>+</span>
                                {intl.formatMessage({
                                  id: Object.entries(formik.values.to).some(
                                    ([key, value]) => !isEmpty(value)
                                  )
                                    ? 'EDIT_ADDRESS'
                                    : 'NEW_ADDRESS',
                                })}
                              </div>
                            </div>
                            <EditShipAddress
                              formik={formik}
                              fromToModal={fromToModal}
                              handleCloseFromToModal={() =>
                                setFormToModal({
                                  show: false,
                                  name: undefined,
                                  data: {},
                                  validate: {},
                                })
                              }
                              name='to'
                              hasReturn
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xxl-4 bg-white rounded shadow-sm border mb-10'>
                      <div className='d-flex border-bottom border-secondary py-4 pt-8'>
                        <span className='fs-3 fw-bolder'>
                          {intl.formatMessage({id: 'PACKAGE_INFO'})}
                        </span>
                      </div>

                      <div className='px-5 py-5'>
                        <div className={clsx('mb-8 d-flex')}>
                          <SelectMultiLevelFormik
                            className={clsx('col-9')}
                            labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                            label={intl.formatMessage({id: 'SERVICE'})}
                            options={optionsService || [{label: '', options: []}]}
                            formik={formik}
                            name='service'
                            required
                          />
                        </div>
                        <div className={clsx('d-flex align-items-center mb-6 mt-2')}>
                          <PackagesMenu
                            data={[
                              ...cloneDeep(inputDimensions),
                              ...cloneDeep(optionsPackageTypes),
                              ...cloneDeep(optionsCustomPackage),
                            ]}
                            labelClassName='col-4 col-form-label text-gray-600 fw-bolder'
                            textLabel={intl.formatMessage({id: 'PACKAGE_TYPE'})}
                            formik={formik}
                            name='parcels.0.package_type'
                            placeholder={`${intl.formatMessage({id: 'SELECT'})}...`}
                          />
                        </div>
                        <div>
                          {Object.entries(formik.getFieldProps('parcels').value).map(
                            ([keyItem, valueItem]: any, index) => {
                              const packageType = formik.values.parcels[0].package_type
                              return (
                                <div key={index}>
                                  {!isEmpty(packageType) &&
                                  packageType !== OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value ? (
                                    <div className='fs-6 text-gray-600'>
                                      {intl.formatMessage({id: 'DIMENSIONS'})}:{' '}
                                      {`${valueItem?.length} x ${valueItem?.width} x ${valueItem?.height} ${valueItem?.dimension_unit}`}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-between'>
                                      <span className='text-muted mb-2'>
                                        {intl.formatMessage({id: 'PACKAGE'})} {index + 1}
                                      </span>
                                    </div>
                                  )}

                                  <div className='row'>
                                    {(isEmpty(packageType) ||
                                      packageType === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value) && (
                                      <div className='col-12 d-flex'>
                                        {['length', 'width', 'height'].map((item, index) => {
                                          return (
                                            <div className='col-3' key={index}>
                                              <InputTextFormik
                                                type='number'
                                                min={0}
                                                className={clsx('me-2')}
                                                labelClassName={'text-muted'}
                                                label={item.charAt(0).toUpperCase()}
                                                formik={formik}
                                                name={`parcels.${keyItem}.${item}`}
                                                checkFormik={false}
                                              />
                                            </div>
                                          )
                                        })}
                                        <div className='col-3'>
                                          <SelectFormik
                                            placeholder=''
                                            label={intl.formatMessage({id: 'UNIT'})}
                                            labelClassName={'text-muted'}
                                            options={OPTION_DIMENSION_UNIT}
                                            name={`parcels.${keyItem}.dimension_unit`}
                                            formik={formik}
                                            checkFormik={false}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <div className='col-12 d-flex mt-3'>
                                      <div className='col-3' key={index}>
                                        <InputTextFormik
                                          type='number'
                                          min={0}
                                          className={clsx('me-2')}
                                          labelClassName={'text-muted'}
                                          label={intl.formatMessage({id: 'WEIGHT'})}
                                          formik={formik}
                                          name={`parcels.${keyItem}.weight`}
                                          checkFormik={false}
                                        />
                                      </div>
                                      <div className='col-3'>
                                        <SelectFormik
                                          label={intl.formatMessage({id: 'UNIT'})}
                                          placeholder=''
                                          labelClassName={'text-muted'}
                                          options={OPTION_WEIGHT_UNIT}
                                          name={`parcels.${keyItem}.weight_unit`}
                                          formik={formik}
                                          checkFormik={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          )}
                          <div className='mb-4'>
                            <div className='d-flex justify-content-end align-items-center text-muted mt-3'>
                              {intl.formatMessage({id: 'TOTAL_WEIGHT'})}:
                              <span className='text-dark fs-1 mx-2'>{totalWeightPackage}</span>{' '}
                              {formik?.values?.parcels[0]?.weight_unit?.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
                disabled={loading.create}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'CREATE'})}
                loadingText={intl.formatMessage({id: 'CREATE'})}
                event={formik.handleSubmit}
                loading={loading.create}
                disabled={loading.create}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CreateReturnLabelModal}
